import {
  createTheme,
  PaletteMode,
} from "@mui/material";
import React from "react";

export function useToggleColorMode() {
  const [mode, setMode] = React.useState<PaletteMode>("light");

  React.useEffect(() => {
    const existingPreference = localStorage.getItem("themeMode");
    if (existingPreference) {
      existingPreference === "light" ? setMode("light") : setMode("dark");
    } else {
      setMode("light");
      localStorage.setItem("themeMode", "light");
    }
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("themeMode", newMode);
          return newMode;
        });
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          background: {
            default: mode === "dark" ? "#3d4159" : "#f6f6f6",
            paper: mode === "dark" ? "#3d4159" : "#f6f6f6",
          },
          primary: {
            main: "#3d4159",
          },
        },
      }),
    [mode]
  );

  return { colorMode, theme };
}
