import { Box, styled } from "@mui/material";

interface Props {
  children?: JSX.Element;
}

const IconDivider = ({ children }: Props) => (
  <Container>
    <LeftDivider />
    {children}
    <RightDivider />
  </Container>
);

const Container = styled(Box)({
  my: "1rem",
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const LeftDivider = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "calc(50% - 2rem)",
  background: `linear-gradient(${
    theme.palette.mode === "light" ? 270 : 90
  }deg, rgba(0,0,0,${
    theme.palette.mode === "light" ? 1 : 0
  }) 0%, rgba(255,255,255,1) 100%)`,
}));

const RightDivider = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "calc(50% - 2rem)",
  background: `linear-gradient(${
    theme.palette.mode === "light" ? 90 : 270
  }deg, rgba(0,0,0,${
    theme.palette.mode === "light" ? 1 : 0
  }) 0%, rgba(255,255,255,1) 100%)`,
}));

export default IconDivider;
