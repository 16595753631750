import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Skeleton,
  TableBody,
  Collapse,
  Paper,
  IconButton,
  CardContent,
  Pagination,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { TextHeading } from "../typography";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { getAnalyticCard } from "../../endpoints/api";
import { setNotification } from "../../redux/notification";

const DetailsLoader = () => (
  <TableRow>
    <TableCell component="th" scope="row">
      <Skeleton variant="text" />
    </TableCell>
    <TableCell align="right">
      <Skeleton variant="text" />
    </TableCell>
    <TableCell align="right">
      <Skeleton variant="text" />
    </TableCell>
  </TableRow>
);

const FinanceDetails = (props: any) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [ticketsLength, setTicketsLength] = useState(0);
  const [isLoadingDetails, setIsLoadingDetails] = useState(true);

  useEffect(() => {
    setIsLoadingDetails(true);
    getAnalyticCard({ konto: props.konto.id, supplier_id: props.company.id })
      .then((e: any) => {
        setTickets(e.finances[0]);
        setTicketsLength(e.finances[1]);
        setIsLoadingDetails(false);
      })
      .catch((e) => {
        dispatch(setNotification({ message: e.message, icon: "error" }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const openToggler = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Paper sx={{ margin: "2rem 0" }}>
      <CardContent
        onClick={() => {
          openToggler();
        }}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <TextHeading>Analiticka kartica: </TextHeading>
        <IconButton aria-label="expand row" size="small">
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </CardContent>
      <Collapse in={open}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell align="right">Duguje</TableCell>
              <TableCell align="right">Potrazuje</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingDetails && <DetailsLoader />}
            {!isLoadingDetails && tickets.length === 0 && (
              <TableRow>
                <TableCell sx={{ textAlign: "center" }} colSpan={3}>
                  Nema podataka u bazi
                </TableCell>
              </TableRow>
            )}

            {!isLoadingDetails &&
              tickets.length > 0 &&
              tickets.map((row: any, index: number) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {moment(row.date).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell align="right">{row.dug}&euro;</TableCell>
                  <TableCell align="right">{row.pot}&euro;</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Pagination
          sx={{ margin: "1rem 0" }}
          onChange={(a, b) => {
            setIsLoadingDetails(true);
            getAnalyticCard({
              konto: props.konto.id,
              supplier_id: props.company.id,
              page: b,
            })
              .then((e: any) => {
                setTickets(e.finances[0]);
                setTicketsLength(e.finances[1]);
                setIsLoadingDetails(false);
              })
              .catch((e) => {
                dispatch(
                  setNotification({ message: e.message, icon: "error" })
                );
              });
          }}
          count={Math.ceil(ticketsLength / 10)}
          color="primary"
        />
      </Collapse>
    </Paper>
  );
};

export default FinanceDetails;
