import { Fragment, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Container } from "@mui/system";
import { Box } from "@mui/material";

import { fetchProducts } from "../endpoints/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import { setCategories, setProducts } from "../redux/product";
import { setItem } from "../redux/cart";
import { setNotification } from "../redux/notification";
import { ItemT } from "../typescript/item";
import OrderItem from "../components/OrderItem";
import SearchBox from "../components/SearchBox";

const NoData = () => (
  <TableRow>
    <TableCell colSpan={3}>Nema proizvoda u bazi</TableCell>
  </TableRow>
);

const Order = () => {
  const dispatch = useDispatch();

  const [productsLoaded, setProductsLoaded] = useState(false);
  const [search, setSearch] = useState("");

  const company = useSelector(
    (state: RootState) => state.companyReducer.company
  );
  const { products, category } = useSelector(
    (state: RootState) => state.productReducer
  );

  useEffect(() => {
    fetchProducts(company.id).then((e: any) => {
      dispatch(setProducts(e.products[0]));

      const set = new Set();
      const categories = e.products[0].filter((e: any) => {
        if (set.has(e.group_id)) return false;
        set.add(e.group_id);
        return true;
      });
      dispatch(
        setCategories(
          categories.map((e: any) => ({ id: e.group_id, label: e.group_name }))
        )
      );
      setProductsLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xs">
      <Box>
        <SearchBox searchHandler={setSearch} />

        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Naziv</TableCell>
              <TableCell align="center">Kol.</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!productsLoaded ? (
              Array(1)
                .fill(1)
                .map((e, i) => (
                  <Fragment key={i}>
                    <OrderItem />
                  </Fragment>
                ))
            ) : products.length === 0 ? (
              <NoData />
            ) : (
              (search
                ? products
                    .filter(
                      (p: any) => p.group_id === (category?.id ?? p.group_id)
                    )
                    .filter((p: any) =>
                      p.ime.toLowerCase().includes(search.toLowerCase())
                    )
                : products.filter(
                    (p: any) => p.group_id === (category?.id ?? p.group_id)
                  )
              ).map((row: ItemT) => (
                <OrderItem
                  key={row.id}
                  item={row}
                  clickHandler={(value) => {
                    dispatch(setItem(value));
                    dispatch(
                      setNotification({
                        message: "Artikal dodat u korpu",
                        icon: "success",
                      })
                    );
                  }}
                />
              ))
            )}
          </TableBody>
        </Table>
      </Box>
    </Container>
  );
};

export default Order;
