import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

type Props = {
  searchHandler: (e: string) => void;
};

const SearchBox = ({ searchHandler }: Props) => {
  return (
    <FormControl
      sx={{ mt: 2, mb: 2, boxShadow: 3 }}
      variant="outlined"
      fullWidth
    >
      <OutlinedInput
        type="text"
        onChange={(e: any) => {
          searchHandler(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        label="Pretraga"
      />
    </FormControl>
  );
};

export default SearchBox;
