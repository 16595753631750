import { string, object } from "yup";

export const emailValidator = string()
  .email("Unesite ispravan email format")
  .required("Required");

export const passwordValidator = string()
  // .min(5, "Password treba da sadrzi minimum 5 karaktera")
  .required("Required");
// .matches(
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
//   "Jedno Veliko slovo, Jedno Malo slovo, Jedan Broj"
// );

export const usernameValidator = string().required("Required");

export const chooseCompany = object()
  .shape({
    label: string().min(1).required("Required"),
    id: string().min(1).required("Required"),
  })
  .required("Required.");

export const chooseShop = object()
  .shape({
    label: string().min(1).required("Required"),
    id: string().min(1).required("Required"),
  })
  .required("Required.");

export const chooseKonto = object()
  .shape({
    label: string().min(1).required("Required"),
    id: string().min(1).required("Required"),
  })
  .required("Required.");
