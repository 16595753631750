import { Box, IconButton, styled } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

type Props = {
  addHandler: () => void;
  removeHandler: () => void;
};

const ItemActions = ({ addHandler, removeHandler }: Props) => (
  <Container>
    <IconButton onClick={addHandler} color="inherit">
      <AddCircleIcon />
    </IconButton>
    <IconButton onClick={removeHandler} color="inherit">
      <RemoveCircleIcon />
    </IconButton>
  </Container>
);

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export default ItemActions;
