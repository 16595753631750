/* eslint-disable import/prefer-default-export */

import { axiosClient } from ".";
import { API } from "../constants/api";
import { Company } from "../typescript/company";
import { Login } from "../typescript/login";

/**
 * Login user.
 */
export function login(params: Login) {
  return axiosClient.post(API.LOGIN, params);
}

/**
 * Logout user and redirect to login page.
 */
export function logout() {
  return axiosClient.get({ url: API.LOGOUT });
}

/**
 * Fetch list of companies.
 */
export function fetchCompanies() {
  return axiosClient.get({ url: API.COMPANIES });
}

/**
 * Fetch list of shops for company.
 */
export function fetchCompanyShop(company: Company) {
  return axiosClient.get({ url: API.SHOPS, params: { firm: company.id } });
}

/**
 * Fetch list of products.
 */
export function fetchProducts(company: string) {
  return axiosClient.get({ url: API.PRODUCTS, params: { company: company } });
}

/**
 * Fetch product categories.
 */
export function fetchProductCategories() {
  return axiosClient.get({ url: API.CATEGORIES });
}

/**
 * Returns user is token is valid, else return false.
 */
export async function tokenIsValid() {
  return axiosClient.get({ url: API.CHECK_TOKEN });
}

/**
 * Submit order.
 */
export async function submitOrder(params: any) {
  return axiosClient.post(API.SUBMIT_ORDER, params);
}

/**
 * Get invoices.
 */
interface Props {
  date: string;
  user?: string;
}
export async function getInvoices(data: Props) {
  return axiosClient.get({ url: API.INVOICES, params: { ...data } });
}

/**
 * Get invoice items.
 */
export async function getInvoiceItems(invoiceID: string) {
  return axiosClient.get({
    url: API.INVOICE_ITEMS,
    params: { invoice: invoiceID },
  });
}

/**
 * Get all users.
 */
export async function getUsers() {
  return axiosClient.get({
    url: API.USERS,
  });
}

/**
 * Get balance.
 */
export async function getBalance(data: any) {
  return axiosClient.get({
    url: API.BALANCE,
    params: { ...data },
  });
}

/**
 * Get analytics card.
 */
export async function getAnalyticCard(data: any) {
  return axiosClient.get({
    url: API.ANALYTIC,
    params: { ...data },
  });
}
