import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  styled,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LogoutIcon from "@mui/icons-material/Logout";

import { ROUTES } from "../../constants/routes";
// import MenuItem from "./MenuItem";
import { logout } from "../../endpoints/api";
import Links from "./Links";
import Categories from "./Categories";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/notification";
import { logout as logoutDispatch } from "../../redux/user";

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SideMenu = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutHandler = () => {
    logout()
      .then(() => {
        localStorage.clear();
        dispatch(logoutDispatch());
        navigate(ROUTES.LOGIN);
      })
      .catch((e) => {
        dispatch(setNotification({ message: e.message, icon: "error" }));
      });
  };

  return (
    <SwipeableDrawer
      anchor={"left"}
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      onOpen={() => props.setIsOpen(true)}
      onClick={() => props.setIsOpen(false)}
      PaperProps={{
        sx: {
          padding: "1rem",
          width: "70vw",
        },
      }}
    >
      <Box>
        <DrawerHeader onClick={() => props.setIsOpen(false)}>
          <ArrowBackIosIcon />
        </DrawerHeader>
      </Box>
      <Divider />
      {location.pathname === ROUTES.ORDER ? <Categories /> : <Links />}
      <Divider />
      <ListItemIcon
        onClick={logoutHandler}
        sx={{
          ml: 2,
          mt: 2,
        }}
      >
        {<LogoutIcon />}{" "}
        <Typography sx={{ ml: 4, fontWeight: "bold" }}>Logout</Typography>
      </ListItemIcon>
    </SwipeableDrawer>
  );
};

const DrawerHeader = styled(ListItemButton)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: "0.5rem",
});

export default SideMenu;
