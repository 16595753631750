import { Box, Button, Divider, LinearProgress, styled } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import Bill from "../components/Bill";
import CartItems from "../components/CartItems";
import { ROUTES } from "../constants/routes";
import { submitOrder } from "../endpoints/api";
import { resetCart } from "../redux/cart";
import { setCompany, setShop } from "../redux/company";
import { setNotification } from "../redux/notification";
import { RootState } from "../redux/rootReducer";

const ShoppingCart = () => {
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoading] = useState(false);
  const { operater: komerc } = useSelector(
    (state: RootState) => (state.userReducer as any).currentUser
  );
  const { id: kup_obj, company_id: kupac } = useSelector(
    (state: RootState) => state.companyReducer.shop
  );
  const items = useSelector((state: RootState) =>
    state.cartReducer.items.map((e: any) => ({
      sifra: e.item.id,
      koli: e.quantity,
      m_cije: e.item.price,
    }))
  );

  const dispatch = useDispatch();
  return (
    <Container>
      <CartItems />

      {!submitLoader ? (
        <Divider sx={{ marginTop: "auto" }} />
      ) : (
        <LinearProgress />
      )}
      <Button
        color="inherit"
        variant="outlined"
        sx={{
          width: "100%",
          marginTop: "auto",
        }}
        disabled={!items.length}
        onClick={() => {
          Swal.fire({
            title: "Da li ste sigurni da želite da naručite ?",
            icon: "warning",
            confirmButtonText: "Da",
            cancelButtonText: "Ne",
            showCancelButton: true,
            showCloseButton: true,
          }).then((res) => {
            if (!res.isConfirmed) {
              return;
            } else {
              setSubmitLoading(true);
              submitOrder({
                invoice: {
                  komerc: komerc,
                  kupac: kupac,
                  kup_obj: kup_obj,
                },
                items: items,
              })
                .then((e) => {
                  dispatch(
                    setNotification({
                      message: "Porudžbina uspješno poslata!",
                      icon: "success",
                    })
                  );
                  dispatch(resetCart());
                  dispatch(setCompany({}));
                  dispatch(setShop({}));
                  navigate(ROUTES.HOME);
                })
                .catch((e) => {
                  dispatch(
                    setNotification({ message: e.message, icon: "error" })
                  );
                });
            }
          });
        }}
      >
        Order
      </Button>
    </Container>
  );
};

const Container = styled(Box)({
  width: "90%",
  marginInline: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "1rem",
});

export default ShoppingCart;
