import { Outlet } from "react-router-dom";
import TopBar from "../components/TopBar/TopBar";
import { tokenIsValid } from "../endpoints/api";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { checkAuth, setUser } from "../redux/user";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../redux/notification";
import { useEffect } from "react";
import { RootState } from "../redux/rootReducer";

const Authorized = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: RootState) => (state.userReducer as any).isAuthenticated
  );

  useEffect(() => {
    tokenIsValid()
      .then((e) => dispatch(setUser(e)))
      .catch((e) => {
        dispatch(setNotification({ message: e.message, icon: "error" }));
        dispatch(checkAuth());
        navigate(ROUTES.LOGIN, { replace: true });
        localStorage.clear();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, isAuthenticated]);

  if (!isAuthenticated) return null;

  return (
    <>
      <TopBar />
      <Outlet />
    </>
  );
};

export default Authorized;
