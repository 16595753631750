import { ActionType } from "./types";
import { AnyAction } from "redux";

const initialState = {
  companies: [],
  company: {},
  shops: [],
  shop: {},
};

export const companyReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.SET_COMPANY:
      return {
        ...state,
        company: action.payload,
        shop: {},
        shops: [],
      };
    case ActionType.SET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case ActionType.SET_SHOP:
      return {
        ...state,
        shop: action.payload,
      };
    case ActionType.SET_SHOPS:
      return {
        ...state,
        shops: action.payload,
      };
    default:
      return state;
  }
};
