import { applyMiddleware, combineReducers } from "redux";
import { userReducer } from "./user/reducers";
import { companyReducer } from "./company/reducers";
import { notificationReducer } from "./notification/reducers";
import { productReducer } from "./product/reducers";
import { cartReducer } from "./cart/reducers";
import { persistStore, persistReducer } from "redux-persist";
import { createStore } from "redux";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { invoiceReducer } from "./invoices/reducers";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  userReducer: userReducer,
  companyReducer: companyReducer,
  notificationReducer: notificationReducer,
  productReducer: productReducer,
  cartReducer: cartReducer,
  invoiceReducer: invoiceReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  undefined,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
