import {
  IconButton,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { useRef } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { ItemT } from "../../typescript/item";

type OrderItemProps = {
  item?: ItemT;
  clickHandler?: (e: any) => void;
};

const OrderItem = (props: OrderItemProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const addItemHandler = () => {
    if (
      inputRef.current?.value === undefined ||
      parseInt(inputRef.current?.value) < 1 ||
      isNaN(parseInt(inputRef.current?.value)) ||
      !props?.clickHandler ||
      !props.item
    )
      return;

    props.clickHandler({
      item: props.item,
      quantity: parseInt(inputRef.current.value),
    });
    inputRef.current.value = "0";
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {props.item?.ime ?? <Skeleton variant="text" />}
      </TableCell>

      <TableCell
        scope="row"
        sx={{
          padding: "0",
        }}
      >
        <TextField
          type="number"
          sx={{ padding: 0 }}
          size="small"
          InputProps={{ style: { fontSize: "0.9rem" } }}
          inputRef={inputRef}
        ></TextField>
      </TableCell>

      <TableCell>
        <IconButton color="inherit" size="large" onClick={addItemHandler}>
          <AddCircleIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default OrderItem;
