export enum ROUTES {
  LOGIN = "/login",
  HOME = "/homepage",
  CHOSE_SHOP = "/choseShop",
  ORDER = "/order",
  DAILY_REPORT = "/dailyReport",
  ADMIN_DAILY_REPORT = "/adminDailyReport",
  SHOPPING_CART = "/cart",
  FINANCE = "/finance",
}
