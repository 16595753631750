import { Box } from "@mui/system";
import { IconButton, useTheme } from "@mui/material";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import LightMode from "@mui/icons-material/LightMode";
import { useContext } from "react";
import { ColorModeContext } from "../../App";

const ThemeSwitch = () => {

	const theme = useTheme();

	const themeContext = useContext(ColorModeContext);
	const toggleColorMode = themeContext.toggleColorMode;

	return (
		<Box>
			 {/* {theme.palette.mode} mode */}
			<IconButton
				// sx={{ ml: 1 }}
				onClick={toggleColorMode}
				color="inherit"
			>
				{theme.palette.mode === "dark" ? (
					<LightMode />
				) : (
					<Brightness3Icon />
				)}
			</IconButton>
		</Box>
	)
}

export default ThemeSwitch;