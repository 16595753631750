import { ActionType } from "./types";

export const setInvoices = (invoices: any) => {
  return {
    type: ActionType.SET_INVOICES,
    payload: invoices,
  };
};

export const setDate = (date: any) => {
  return {
    type: ActionType.SET_DATE,
    payload: date,
  };
};
