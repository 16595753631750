import { PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setNotification } from "../../redux/notification";
import { RootState } from "../../redux/rootReducer";

const Notifications = (props: PropsWithChildren) => {
  const dispatch = useDispatch();
  const notification = useSelector(
    (state: RootState) => state.notificationReducer.notification
  );
  useEffect(() => {
    if (notification) {
      Swal.fire({
        toast: true,
        position: "top",
        title: notification.message,
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        icon: notification.icon,
      });
      dispatch(setNotification(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  return <>{props.children}</>;
};

export default Notifications;
