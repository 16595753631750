import { PropsWithChildren, useState } from "react";
import NoInternetError from "../../pages/NoInternetError";

const NoInternet = (props: PropsWithChildren) => {
  const [hasInternet, setHasInternet] = useState(navigator.onLine);

  window.addEventListener("offline", () => {
    setHasInternet(false);
  });

  window.addEventListener("online", () => {
    setHasInternet(true);
    // window.location.reload(false);

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          window.location.reload();
        });
      });
    }
  });

  return <div>{!hasInternet ? <NoInternetError /> : props.children}</div>;
};

export default NoInternet;
