import { Container, Box, Button } from "@mui/material";
import { chooseCompany, chooseShop } from "../utils/validators";
import { object } from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanies, fetchCompanyShop } from "../endpoints/api";
import { useEffect, useState } from "react";
import { setCompanies, setCompany, setShop, setShops } from "../redux/company";
import { RootState } from "../redux/rootReducer";
import { ROUTES } from "../constants/routes";
import { useNavigate } from "react-router-dom";
import { Company } from "../typescript/company";
import AutoComplete from "../components/FormikInput/AutoComplete";

const ChoseShop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [companiesLoaded, setCompaniesLoaded] = useState(false);
  const [shopsLoaded, setShopsLoaded] = useState(false);

  const { companies, company, shops, shop } = useSelector(
    (state: RootState) => state.companyReducer
  );

  useEffect(() => {
    if (!companies.length) {
      fetchCompanies().then((data: any) =>
        dispatch(setCompanies(data?.firms[0]))
      );
    }
    if (company?.id) {
      fetchCompanyShop(company as Company)
        .then((e: any) => {
          dispatch(setShops(e.shops[0]));
          setShopsLoaded(true);
        })
        .catch();
    }
    setCompaniesLoaded(true);
    if (shops.length) setShopsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = object({
    company: chooseCompany,
    shop: chooseShop,
  });

  const formik = useFormik({
    initialValues: {
      company: Object.keys(company).length === 0 ? null : company,
      shop: Object.keys(shop).length === 0 ? null : shop,
    },
    validationSchema,
    onSubmit: (values) => {
      navigate(ROUTES.ORDER);
      formik.resetForm();
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" onSubmit={formik.handleSubmit}>
        <AutoComplete
          isLoaded={companiesLoaded}
          options={companies}
          formik={formik}
          changeHandler={(e: any, value: any) => {
            setShopsLoaded(false);
            formik.setFieldValue("company", value);
            formik.setFieldValue("shop", null);
            dispatch(setCompany(value));
            fetchCompanyShop(value as Company)
              .then((e: any) => {
                dispatch(setShops(e.shops[0]));
                setShopsLoaded(true);
              })
              .catch();
          }}
          label="Odaberite firmu"
          id="company"
        />
        {company?.id && (
          <AutoComplete
            isLoaded={shopsLoaded}
            options={shops}
            formik={formik}
            changeHandler={(e: any, value: any) => {
              formik.setFieldValue("shop", value);
              dispatch(setShop(value));
            }}
            label="Odaberite prodavnicu"
            id="shop"
          />
        )}
        <Button
          variant="outlined"
          color="info"
          type="submit"
          sx={{
            position: "fixed",
            bottom: "10px",
            right: "15px",
            left: "15px",
            padding: "10px",
          }}
        >
          Nastavi
        </Button>
      </Box>
    </Container>
  );
};

export default ChoseShop;
