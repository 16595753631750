import { Box, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { decrement, remove, setItem } from "../../redux/cart";
import { CartItemT } from "../../typescript/item";
import InfoContainer from "./InfoContainer";
import ItemActions from "./ItemActions";

const Item = (props: CartItemT) => {
  const dispatch = useDispatch();

  const deleteHandler = () => {
    dispatch(remove(props.item.id));
  };

  const addHandler = () => {
    dispatch(setItem({ item: props.item, quantity: 1 }));
  };

  const removeHandler = () => {
    dispatch(decrement({ id: props.item.id }));
  };

  return (
    <ItemContainer>
      <InfoContainer {...props} deleteHandler={deleteHandler} />
      <ItemActions addHandler={addHandler} removeHandler={removeHandler} />
    </ItemContainer>
  );
};

const ItemContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export default Item;
