import { Box, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { CartItemT } from "../../typescript/item";
import Item from "./Item";

const CartItems = () => {
  const { items } = useSelector((state: RootState) => state.cartReducer);
  return (
    <ItemsContainer>
      {items.map((item: CartItemT) => (
        <Item key={item.item.id} {...item} />
      ))}
    </ItemsContainer>
  );
};

const ItemsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem",
});

export default CartItems;
