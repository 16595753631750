import { PropsWithChildren, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Container } from "@mui/system";
import { Autocomplete, Box, Skeleton, styled, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { getInvoices, getUsers } from "../endpoints/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import { setInvoices } from "../redux/invoices";
import { setNotification } from "../redux/notification";
import CollapsableRow from "../components/CollapsableRow";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const RowMessage = (props: PropsWithChildren) => (
  <TableRow>
    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
      {props.children}
    </TableCell>
  </TableRow>
);

const Loader = () => (
  <TableRow>
    <TableCell></TableCell>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
  </TableRow>
);

const AdminDailyReport = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [userID, setUserID] = useState("");
  const [date, setDate] = useState(
    moment(new Date()).format("YYYY-MM-DD 00:00:00")
  );

  const invoices = useSelector(
    (state: RootState) => state.invoiceReducer.invoices
  );

  const handleDateChange = (newDate: any) => {
    setDate(moment(newDate).format("YYYY-MM-DD 00:00:00"));
  };

  useEffect(() => {
    getUsers()
      .then((e: any) => {
        let users = e.users.map((e: any) => ({
          id: e.operater,
          label: e.username,
        }));
        setUsers(users);
      })
      .catch((e: any) => {
        dispatch(setNotification({ message: e.message, icon: "error" }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(setInvoices([]));
    let data: any = { date };
    if (userID) {
      data["user"] = userID;
    }
    getInvoices(data)
      .then((e: any) => {
        dispatch(setInvoices(e.products[0]));
        setIsLoading(false);
      })
      .catch((e) => {
        dispatch(setNotification({ message: e.message, icon: "error" }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, userID]);

  return (
    <Container maxWidth="xs">
      <Box>
        <SelectContainer>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Datum"
              inputFormat="MM/dd/yyyy"
              value={date}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField sx={{ width: "100%" }} {...params} />
              )}
            />
          </LocalizationProvider>
        </SelectContainer>

        <Autocomplete
          disablePortal
          id="id"
          options={users}
          onChange={(a, b: any) => {
            setUserID(b.id);
          }}
          disableClearable
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Komercijalista" />
          )}
        />

        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Kupac</TableCell>
              <TableCell align="left">Objekat</TableCell>
              <TableCell align="center">Br.Rač</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <Loader />}
            {!isLoading && invoices.length === 0 && (
              <RowMessage>Nema podataka u bazi</RowMessage>
            )}
            {!isLoading &&
              invoices.length > 0 &&
              invoices.map((row: any) => (
                <CollapsableRow key={row.id} row={row} />
              ))}
          </TableBody>
        </Table>
      </Box>
    </Container>
  );
};

const SelectContainer = styled(Box)({
  paddingTop: "3rem",
  paddingBottom: "2rem",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default AdminDailyReport;
