import { Box, Button, styled } from "@mui/material";
import { CartItemT } from "../../typescript/item";
import { TextGray, TextHeading } from "../typography";

interface Props extends CartItemT {
  deleteHandler: () => void;
}

const InfoContainer = ({ item, quantity, deleteHandler }: Props) => {
  return (
    <Container>
      <TextHeading>{item.ime}</TextHeading>
      <ItemPrices>
        <TextGray>x {quantity}</TextGray>
        <Button
          onClick={deleteHandler}
          color="error"
          size="small"
          variant="outlined"
        >
          Delete
        </Button>
      </ItemPrices>
    </Container>
  );
};

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
});

const ItemPrices = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
});

export default InfoContainer;
