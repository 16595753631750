import { TextField } from "@mui/material";

const Input = ({ name, formik, type = "text" }: any) => (
  <TextField
    sx={{ boxShadow: 3 }}
    margin="normal"
    required
    fullWidth
    name={name}
    label={name.charAt(0).toUpperCase() + name.slice(1)}
    type={type}
    id={name}
    value={formik.values[name]}
    onChange={formik.handleChange}
    error={formik.touched[name] && Boolean(formik.errors[name])}
    helperText={formik.touched[name] && formik.errors[name]}
  />
);

export default Input;
