import { Container, Box } from "@mui/material";
import IconDivider from "../components/IconDivider";
import image1 from "../assets/img/test/1s.jpg";
import image2 from "../assets/img/test/5s.jpg";
import image3 from "../assets/img/test/12s.jpg";
import image4 from "../assets/img/test/2s.jpg";
import ImageText from "../components/ImageText";
import Smiley from "../components/Icons/Smiley";
import { TextGray, TextHeading } from "../components/typography";
import { ROUTES } from "../constants/routes";

const testCards = [
  {
    image: image1,
    title: "Naručivanje",
    text: "Narudžbenica, brzo i jednostavno.",
    route: ROUTES.CHOSE_SHOP,
  },
  {
    image: image2,
    title: "Dnevni izvještaj",
    text: "Pregled dnevnih narudžbenica",
    route: ROUTES.DAILY_REPORT,
  },
  {
    image: image3,
    title: "Finansije",
    text: "Pregled analitkih kartica komitenata",
    route: ROUTES.FINANCE,
  },
  {
    image: image4,
    title: "Kontrola",
    text: "Periodicni izvještaji po komercijalistima",
    route: ROUTES.ADMIN_DAILY_REPORT,
  },
];

const HomePage = () => (
  <Container maxWidth="xs">
    <Box my="2rem">
      <Box textAlign="center">
        <TextHeading>Agromont</TextHeading>
        <TextGray sx={{ mb: 1 }}>Živinarska farma</TextGray>
      </Box>

      <IconDivider>
        <Smiley />
      </IconDivider>

      <TextGray textAlign={"center"} my="1rem">
        Brzo i jednostavno naručivanje naših proizvoda putem mobilnih telefona,
        sve u cilju unapredja saradnje sa našim klijentima
      </TextGray>
    </Box>
    <Box
      sx={{
        display: "flex",
        my: "2rem",
      }}
    >
      {testCards.slice(0, 2).map((e, i) => (
        <ImageText key={i} {...e} />
      ))}
    </Box>
    <Box
      sx={{
        display: "flex",
        my: "2rem",
      }}
    >
      {testCards.slice(2, 4).map((e, i) => (
        <ImageText key={i} {...e} />
      ))}
    </Box>
  </Container>
);

export default HomePage;
