import { CartItemT } from "../../typescript/item";
import { ActionType } from "./types";

export const setItem = (order: CartItemT) => {
  return {
    type: ActionType.SET_ITEM,
    payload: order,
  };
};

export const remove = (id: any) => {
  return {
    type: ActionType.REMOVE,
    payload: id,
  };
};

export const decrement = (id: any) => {
  return {
    type: ActionType.DECREMENT_ITEM,
    payload: id,
  };
};

export const resetCart = () => {
  return {
    type: ActionType.RESET,
  };
};
