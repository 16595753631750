import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HomeIcon from "@mui/icons-material/Home";
import { ROUTES } from "../../constants/routes";
import MenuItem from "./MenuItem";

const menuItems = [
  {
    icon: <HomeIcon />,
    label: "Početna",
    route: ROUTES.HOME,
  },
  {
    icon: <ShoppingBasketIcon />,
    label: "Naručivanje",
    route: ROUTES.CHOSE_SHOP,
  },
  {
    icon: <AccountBalanceIcon />,
    label: "Finansije",
    route: ROUTES.FINANCE,
  },
  {
    icon: <SummarizeIcon />,
    label: "Dnevni izvještaj",
    route: ROUTES.DAILY_REPORT,
  },
];

function Links() {
  return (
    <>
      {menuItems.map((e, i) => (
        <MenuItem key={i} {...e} />
      ))}
    </>
  );
}

export default Links;
