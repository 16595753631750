import { Box, Card, Typography, styled, Skeleton } from "@mui/material";
import { Finance } from "../../typescript/finance";
import { TextGray } from "../typography";

interface Props extends Finance {
  isLoaded: boolean;
}
const FinanceData = (props: Props) => {
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <CardContainer>
          <InnerCard>
            <CardText style={{ color: "red" }}>
              <span>&euro;</span>
              {props.isLoaded ? (
                (
                  (props.duguje_out ?? 0) - (props.potrazuje_out ?? 0)
                ).toLocaleString()
              ) : (
                <Skeleton sx={{ flex: 1 }} variant="text" />
              )}
            </CardText>
            <TextGray>Saldo van valute</TextGray>
          </InnerCard>
          <InnerCard>
            <CardText style={{ color: "green" }}>
              <span>&euro;</span>
              {props.isLoaded ? (
                ((props.duguje ?? 0) - (props.potrazuje ?? 0)).toLocaleString()
              ) : (
                <Skeleton sx={{ flex: 1 }} variant="text" />
              )}
            </CardText>
            <TextGray>Saldo</TextGray>
          </InnerCard>
        </CardContainer>
        <CardContainer>
          <InnerCard>
            <CardText>
              <span>&euro;</span>
              {props.isLoaded ? (
                (props.duguje ?? 0).toLocaleString()
              ) : (
                <Skeleton sx={{ flex: 1 }} variant="text" />
              )}
            </CardText>
            <TextGray>Duguje</TextGray>
          </InnerCard>
          <InnerCard>
            <CardText>
              <span>&euro;</span>
              {props.isLoaded ? (
                (props.potrazuje ?? 0).toLocaleString()
              ) : (
                <Skeleton sx={{ flex: 1 }} variant="text" />
              )}
            </CardText>
            <TextGray>Potražuje</TextGray>
          </InnerCard>
        </CardContainer>
      </Box>
    </Box>
  );
};

const CardContainer = styled(Box)({ display: "flex", gap: "1rem" });

const InnerCard = styled(Card)({
  position: "relative",
  flex: "1",
  padding: "2rem 1rem",
});

const CardText = styled(Typography)(({ theme }) => ({
  display: "flex",
  gap: "0.25rem",
  fontSize: "1.5rem",
  fontWeight: 700,
  color: theme.palette.mode === "light" ? "#27316b" : "white",
}));

export default FinanceData;
