import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { TextGray, TextHeading } from "../typography";
import { RootState } from "../../redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../redux/notification";
interface Props {
  image: string;
  title: string;
  text: string;
  route: string;
}

const ImageText = ({ image, title, text, route }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { role } = useSelector(
    (state: RootState) => (state.userReducer as any).currentUser
  );

  return (
    <Container
      onClick={() => {
        if (title === "Dnevni izvještaj" && role === "admin") {
          dispatch(
            setNotification({
              message: "Administratori koriste kontrolnu tablu!",
              icon: "warning",
            })
          );
          return;
        }
        if (role === "user") {
          if (route === "/finance" || route === "/adminDailyReport") {
            dispatch(
              setNotification({
                message: "Nemate pravo pristupa!",
                icon: "error",
              })
            );
          } else {
            navigate(route);
          }
        } else {
          navigate(route);
        }
      }}
    >
      <img src={image} alt="card" style={{ borderRadius: "0.5rem" }} />
      <TextHeading>{title}</TextHeading>
      <TextGray maxWidth="25ch">{text}</TextGray>
    </Container>
  );
};

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "start",
  textAlign: "center",
  gap: "0.5rem",
  padding: "1rem 0",
  borderRadius: "0.5rem",

  "&:hover": {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
});

export default ImageText;
