import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Skeleton } from "@mui/material";
import { getInvoiceItems } from "../../endpoints/api";

const Loader = () => (
  <TableRow>
    <TableCell component="th" scope="row">
      <Skeleton variant="text" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    {/* <TableCell align="right">
      <Skeleton variant="text" />
    </TableCell> */}
  </TableRow>
);

const Row = (props: any) => (
  <TableRow>
    <TableCell component="th" scope="row">
      {props.name}
    </TableCell>
    <TableCell>{parseInt(props.quantity)}</TableCell>
    {/* <TableCell align="right">{props.price.toFixed(2)}&euro;</TableCell> */}
  </TableRow>
);

const NoData = () => (
  <TableRow>
    <TableCell sx={{ textAlign: "center" }} colSpan={4}>
      Nema podataka
    </TableCell>
  </TableRow>
);

function CollapsableRow(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);

  const openToggler = () => {
    setOpen((prev) => !prev);
    if (!items.length)
      getInvoiceItems(row.id)
        .then((e: any) => {
          setItems(e.products[0]);
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              openToggler();
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{
            width: "60%",
          }}
        >
          {row.company_name}
        </TableCell>
        <TableCell scope="row">{row.shop_name}</TableCell>
        <TableCell scope="row" align="center">
          {row.invoice_id}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: "1rem 0" }}>
              <Typography variant="h6" gutterBottom component="div">
                Proizvodi
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Artikal</TableCell>
                    <TableCell>Kol.</TableCell>
                    {/* <TableCell align="right">Cijena</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading && <Loader />}
                  {!isLoading && items.length === 0 && <NoData />}
                  {!isLoading &&
                    items.length > 0 &&
                    items.map((e: any) => <Row key={e.id} {...e} />)}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default CollapsableRow;
