import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductCategories } from "../../endpoints/api";
import { setCategories, setCategory } from "../../redux/product";
import { RootState } from "../../redux/rootReducer";
import MenuItem from "./MenuItem";

const Categories = () => {
  const dispatch = useDispatch();
  const { categories, category } = useSelector(
    (state: RootState) => state.productReducer
  );

  useEffect(() => {
    if (!categories.length)
      fetchProductCategories().then((e: any) => {
        dispatch(setCategories(e.categories[0]));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MenuItem
        onClick={() => {
          dispatch(setCategory({}));
        }}
        label="SVI PROIZVODI"
        selected={!category?.id}
      />
      {categories.map((e: any, i: number) => (
        <MenuItem
          key={i}
          onClick={() => {
            dispatch(setCategory(e));
          }}
          selected={e.id === category?.id}
          {...e}
        />
      ))}
    </>
  );
};

export default Categories;
